<template>
  <div class="attendClass" v-loading.fullscreen.lock="fullscreenLoading">
      <div class="attendClassDetails" v-loading="true"
            element-loading-text=""
            element-loading-spinner=""
            element-loading-background="rgba(0, 0, 0, 0.5)">
        <div class="student" :style="stuList.length < 7 ? 'display: flex;flex-wrap: wrap;align-content: center;' : ''">
            <img :src='reduceImg' alt="cry" class="cryImg" v-if="cryStatus"  :class="cryStatus?'show-ani':''">
            <div class="studentList" :style="stuList.length < 7 ? 'display: flex;flex-wrap: wrap;justify-content: center;' : ''">
                <div class="studentDetails" v-for="(item,index) in stuList" :key="index">
                    <div class="stu" @click="checkStu(index)" :style="item.isCheck ? item.isCheckBorder : item.unCheckBorder">
                        <div class="avatar" ref='avatar'>
                            <img :src='item.stuImageUrl? item.stuImageUrl  : item.stuGender === 0 ? boyAva : girlAva' :alt="item.stuName" class="stuImageUrl " style="border-radius: 50%;">
                            <span v-for="(it,inx) in item.scoreDetailList" :key="it.id" class="scoreItem"  :style="{'transform': `rotate(${inx * (360/item.scoreDetailList.length)}deg)`}" >
                                <span :class="item[`showAni${inx}`]?'show-ani':''" style="display:block;" v-if="it.itemLv">
                                    <img :src="it.aflpCourseTimeSettingItem.icon" alt="item.name"  class="aflpScoringItemIcon" style="border-radius: 50%;" :style="{'transform': `rotate(${-(inx * (360/item.scoreDetailList.length))}deg)`}" v-if="it.aflpCourseTimeSettingItem" >
                                </span>
                            </span>
                            <span v-for="(it,inx) in item.scoreDetailList" :key="it.id + 100000" class="lvItem"  :style="{'transform': `rotate(${inx * (360/item.scoreDetailList.length)}deg)`}" >
                                <span class='itemLv' style="border-radius: 50%;" :style="{'transform': `rotate(${-(inx * (360/item.scoreDetailList.length))}deg)`}" v-if="it.aflpCourseTimeSettingItem">
                                    <span class="word">{{it.itemLv}}</span>
                                </span>
                            </span>
                        </div>
                    </div> 
                    <div class="stuName">{{item.stuName}}</div>
                </div>
            </div>
            <div class="leftArrow" @click="pageUp" v-if="pageTotal > 0 && page > 1">
                <img :src='left' alt="上一页" class="leftArrowImg" style="border-radius: 50%;">
            </div>
            <div class="rightArrow" @click="nextPage" v-if="pageTotal > 0 && page < pageTotal">
                <img :src='right' alt="下一页" class="rightArrowImg" style="border-radius: 50%;">
            </div>
            <div class="card">
                <div class="score">
                    <div class="scoreBtn">
                            <div class="scoreBtnCardList" v-for="(item,index) in scoreBtnCardList" :key="item.name">
                                <div class="scoreBtnCard" @click="checkScore(index)" :style="item.isCheck ? item.isCheckBorder : item.unCheckBorder">
                                    <div class="scoreBtnCardIcon" ref="scoreBtnCardIcon">
                                            <img :src="item.icon" alt="奖杯" ref="avaImg" class="avaImg">
                                    </div>
                                    <div class="scoreBtnCardTitle">
                                        {{item.name}}
                                    </div>
                                </div>
                            </div>
                            <div  ref="scoImg" v-for="(item,index) in scoreBtnCardList" :key="index">
                                <div class="flySco" ref="flySco" :key="index"></div>
                            </div>
                    </div>
                    <div class="operation">
                        <span class="buttonOne" @click="selAll"><span class="btnOne">{{btnSelAllName}}</span></span>
                        <span class="buttonOne"  @click="sllCounter"><span class="btnOne">{{btnCouterName}}</span></span>
                        <span class="buttonTwo" :style="isSelect ? 'background: #4BD9B4;box-shadow: 0px 5px #36A689;' : 'background: #c5c8ce;box-shadow: 0px 5px #808695;'" @click="plus"><span class="btnTwo"><i class="el-icon-plus"></i></span></span>
                        <span class="buttonTwo" :style="isSelect ? 'background: #4BD9B4;box-shadow: 0px 5px #36A689;' : 'background: #c5c8ce;box-shadow: 0px 5px #808695;'"  @click="reduce"><span class="btnTwo"><i class="el-icon-minus"></i></span></span>
                        <span class="buttonOne"  @click="goDetails"><span class="btnOne">{{btnDetailsName}}</span></span>
                    </div>
                </div>
                <div class="scoreOpacity"></div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import { page } from './../../api/index.js';
import { Message } from 'element-ui';
export default {
  name: "attendClass",
  data() {
    return {
        paikeId: '',
        paikeId_cur: '',
        loading: false,
        btnDetailsName: '实时详情',
        btnEndName: '下课',
        btnSelAllName: '全选',
        btnCouterName: '反选',
        scoreTitle: '课堂评分细则',
        scoreOpacityShow: false,
        cryStatus: false,
        iconNum: 10,
        isCheckList: [],
        trophyId: '',
        stuList:[],
        scoreBtnCardList: [],
        girlAva: require('../../assets/image/stuGirl.png'),
        boyAva: require('../../assets/image/stuBoy.png'),
        left: require('../../assets/image/left.png'),
        right: require('../../assets/image/right.png'),
        reduceImg: require('../../assets/image/reduce.png'),
        fullscreenLoading: false,
        tiemr: null,
        punishmentTiemr: null,
        itemId: null,
        total: null,
        pageSize: 12,
        page: 1,
        pageTotal: null,
        resultList: [],
        allStuList: []
    };
  },
 
  created() {
        this.getCoursePaikeId();
        // this.getPaikeId().then(() => {
        //     console.log(this.paikeId_cur)
        //     this.getStudentsInfo();
        //     this.setPosition();
        // }).catch(() => {
        //     if(!this.paikeId){
        //         this.$router.push({path: "home"});               
        //     }
        // });
  },
  mounted(){},
  computed:{
      isSelect(){
          let isCheckStuList = this.stuList.filter(item => {
             return item.isCheck
        });
        let selScoreList = this.scoreBtnCardList.filter(item => {
             return item.isCheck
        });
        return isCheckStuList.length > 0 && selScoreList.length > 0
      },
      docmentVisibility() {
        return this.$store.state.user.docmentVisibility;
      },
  },
  methods: {
        getStatus(){
            let id = this.paikeId ? this.paikeId : '0';
            page.goToPage(id, res => {
                if(res.status === 1) {
                    if(res.data === 'index'){
                        return this.$router.replace({name: 'home'})
                    }else if(res.data === 'signIn'){
                            this.$router.replace({name: 'checkWork'})
                    }
                }else {
                    Message.error(res.message);
                }
            });    
        },
        async getCoursePaikeId(){
            this.fullscreenLoading = true;
            await page.getCoursePaikeId('', res => {
                if(res.status === 1) {
                    if(res.data && res.data.courseName){
                        this.paikeId = res.data.scheduleId;
                        this.getStudentsInfo();
                        this.setPosition();
                        this.getStatus();
                    }else{
                        Message.error('未查询到排课Id');
                        this.fullscreenLoading = false;
                    }
                }else {
                    Message.error(res.message);
                    this.fullscreenLoading = false;
                }
            });
            // const that = this;
            // return new Promise(function(resolve,reject){
            //     page.getCurrDayOnClass({}, res => {
            //         if(res.status === 1) {
            //             if(res.data && res.data.courseName){
            //                 that.paikeId_cur = res.data.paikeId;
            //                 resolve()
            //             }else{
            //                 that.paikeId_cur = '';
            //                 reject()
            //             }   
            //         }else {
            //             Message.error(res.message);
            //         }
            //         that.fullscreenLoading = false;
            //     });
            // })
        },
      // 获取学员列表，同时根据status筛选出已签到学员包含迟到学员
      getStudentsInfo(){
        //   this.fullscreenLoading = true;
          let id = this.paikeId;
          page.getStudentListInStudy(id, res => {
            if(res.status === 1) {
                let stuArrList = res.data.filter(item => {
                    return item.status === 0 || item.status === 1
                })
               let newList = stuArrList.map(item => ({
                    ...item,scoreDetailList: item.scoreDetailList ? item.scoreDetailList : [],
                    x: null,y:null,
                    isCheck: false,
                    isCheckBorder: 'border: 5px solid #2CBD74;box-shadow: #fff 0px 0px 8px 8px;',
                    unCheckBorder: 'border: 1px solid #fff'
                }))
                this.allStuList = newList;
                this.resultList = [];
                this.total = stuArrList.length;
                for (var i = 0, j = newList.length; i < j; i += this.pageSize) {
                    this.resultList.push(newList.slice(i, i + this.pageSize));
                }
                this.pageTotal = this.resultList.length;
                this.stuList = this.resultList.length > 0 ? this.resultList[this.page-1] : [];
                this.fullscreenLoading = false;
            }else {
                this.fullscreenLoading = false;
                Message.error(res.message);
            }
        });
      },
      pageUp(){
          this.fullscreenLoading = true;
          this.page -=1;
          this.stuList = this.resultList[this.page-1];
          setTimeout(() => {
              this.fullscreenLoading = false;
          },300)
      },
      nextPage(){
          this.fullscreenLoading = true;
          this.page +=1;
          this.stuList = this.resultList[this.page-1];
          setTimeout(() => {
              this.fullscreenLoading = false;
          },300)
      },
      // 设置每个细则的初始位置
      async setPosition(){
            await this.getScoringItemList()
      },
      // 获取细则列表
      async getScoringItemList(){
          let id = this.paikeId;
          await page.getScoringItemList(id, res => {
            if(res.status === 1) {
                let scoreLIst = res.data.filter(item => {
                    return item.status !== 0
                })
                this.scoreBtnCardList = scoreLIst.map(item =>({
                    ...item,isCheck: false,x: null,y: null,fixX: null,fixY: null,isCheckBorder: 'border: 6px solid #2CBD74;box-shadow: #fff 0px 0px 8px 8px;',
                    unCheckBorder: 'border: 6px solid #9D7635'}))
            }else {
                Message.error(res.message);
            }
        });
      },
      // 选择学员
     checkStu(index){
         let isCheck = this.stuList[index].isCheck
         this.stuList[index].isCheck = !isCheck;
     },
     // 选择奖杯
     checkScore(index){
         let isCheck = this.scoreBtnCardList[index].isCheck
         this.scoreBtnCardList[index].isCheck = !isCheck;
     },
     // 全选
     selAll(){
        this.allStuList.forEach(item => {
             item.isCheck = true
        })
     },
     // 反选
     sllCounter(){
         this.allStuList.forEach(item => {
             item.isCheck = false
         })
     },
     // 细则减一
     reduce(){
        this.isCheckList = this.stuList.filter(item => {
             return item.isCheck
        });
        let selScoreList = this.scoreBtnCardList.filter(item => {
             return item.isCheck
        });
        if( this.isCheckList.length === 0) return false
        if( selScoreList.length === 0) return false
        // 操作时其他细则不允许操作
        let c = document.getElementsByClassName("scoreOpacity");
         c[0].style.opacity = 0.5;
         c[0].style.zIndex = 10000000;
         c[0].style.transition = 'all 0.5s';
         let audio = new Audio();
        audio.src = require('../../assets/audio/punishment.mp3');
        let playPromise; 
        playPromise = audio.play();
        let second = 1;
        let that = this;
        if (playPromise) {
            playPromise.then(() => {
                // 音频加载成功
                // 音频的播放需要耗时
                that.punishmentTiemr = setInterval(() => {
                    second--;
                    if (second <= 0) {
                    audio.pause()
                    clearInterval(that.punishmentTiemr);
                    }
                }, 1000);
            }).catch((e) => {
            // 音频加载失败
            console.error(e);
            });
        };
        this.cryStatus = true;
        // this.stuList.forEach((item,i) => {
        //     this.$forceUpdate()
        //     if(item.isCheck){
        //          console.log(i)
        //          item.scoreDetailList.forEach((it,index) => {
        //             this.scoreBtnCardList.forEach(ite => {
        //                 if(ite.isCheck){
        //                     if(ite.id === it.aflpCourseTimeSettingItem.id){
        //                         console.log(index)
        //                         item[`showAni${index}`] = true
        //                     }
        //                 }
                        
        //             })
        //         })
        //      }
        // })
        setTimeout(() => {
            // this.stuList.forEach(item => {
            //     item.scoreDetailList.forEach((it,index) => {
            //         item[`showAni${index}`] = false
            //     })
            // })
            this.cryStatus = false;
            this.$forceUpdate()
            c[0].style.opacity = 0;
            c[0].style.zIndex = 0;
            c[0].style.transition = 'all 0.5s';
            this.stuMark(0);
        },1500)
        this.$forceUpdate()
     },
     plus(){
        this.isCheckList = this.stuList.filter(item => {
             return item.isCheck
         });
        let selScoreList = this.scoreBtnCardList.filter(item => {
             return item.isCheck
        });
         if( this.isCheckList.length === 0) return false
         if( selScoreList.length === 0) return false
         // 操作时其他细则不允许操作
         let c = document.getElementsByClassName("scoreOpacity");
         c[0].style.opacity = 0.5;
         c[0].style.zIndex = 10000000;
         c[0].style.transition = 'all 0.5s';
         let audio = new Audio();
                audio.src = require('../../assets/audio/applause.mp3');
                let playPromise; 
                playPromise = audio.play();
                let second = 1;
                let that = this;
                if (playPromise) {
                    playPromise.then(() => {
                        // 音频加载成功
                        // 音频的播放需要耗时
                        that.tiemr = setInterval(() => {
                            second--;
                            if (second <= 0) {
                            audio.pause()
                            clearInterval(that.tiemr);
                            }
                        }, 1000);
                    }).catch((e) => {
                    // 音频加载失败
                    console.error(e);
                    });
        };
        this.stuList.forEach(item => {
            this.$forceUpdate()
            if(item.isCheck){
                 item.scoreDetailList.forEach((it,index) => {
                    this.scoreBtnCardList.forEach(ite => {
                        if(ite.isCheck){
                            if(it.aflpCourseTimeSettingItem && ite.id === it.aflpCourseTimeSettingItem.id){
                                item[`showAni${index}`] = true
                            }
                        }
                        
                    })
                })
             }
        })
        setTimeout(() => {
            this.stuList.forEach(item => {
                item.scoreDetailList.forEach((it,index) => {
                    item[`showAni${index}`] = false
                })
            })
            this.$forceUpdate()
            c[0].style.opacity = 0;
            c[0].style.zIndex = 0;
            c[0].style.transition = 'all 0.5s';
            this.stuMark(1);
        },1500)
        this.$forceUpdate()
     },
     // 学员评分
    async stuMark(operation){
         this.isCheckList = this.allStuList.filter(item => {
             return item.isCheck
         });
         let stuIds = this.isCheckList.map(item => item['basicId']);
        let selScoreList = this.scoreBtnCardList.filter(item => {
             return item.isCheck
         });
         let itemIdSet = selScoreList.map(item => item['id']);
        const params = {
            itemIdSet: itemIdSet,
            operation: operation,
            schedulingId: this.paikeId,
            stuBasicSet: stuIds
        }
        await page.aflpStudentScoreMark(params, res => {
            if(res.status === 1) {
                this.scoreBtnCardList.forEach(item => {
                    item.isCheck = false
                })
                this.getStudentsInfo()
            }else {
                Message.error(res.message);
            }
        });
    },
    goDetails(){
        this.$router.push({name: 'realTimeDetails'})
    },
  },
};
</script>

<style lang="less" scoped>
.attendClass {
  height: 100%;
  width: 100%;
  z-index: 0;
  background: url("../../assets/image/guide.png") center/cover no-repeat;
    .attendClassDetails{
        padding: 10px;
        .student{
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            padding: 15px 40px 30px 40px;
            .leftArrow{
                z-index: 10;
                position: absolute;
                top: 35%;
                left: 2%;
                cursor: pointer;
            }
            .rightArrow{
                z-index: 10;
                position: absolute;
                top: 35%;
                right: 2%;
                cursor: pointer;
            }
            .studentList{
                width: 90%;
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                margin-left: 5%;
            }
            .studentDetails{
                flex: 0 0 15%;
                box-sizing: border-box;
                margin-right: 2%;
                margin-bottom: 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                z-index: 10;
                .stuName{
                    font-size: 24px;
                    margin-top: 20px;
                    z-index: 10;
                    color: #fff;
                }
                .stu{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 200px;
                    height: 200px;
                    background: #4BD9B4;
                    border-radius: 30px;
                    cursor: pointer;
                    border: none !important;
                    box-shadow: 0px 5px #36A689;
                }
                .stu:hover{
                    transition: all  0.5s;
                }
                .avatar{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    .scoreItem{
                        display: flex;
                        flex-direction: row;
                        // align-items: center;
                        justify-content: center;
                        margin: 0;
                        list-style-type: none;
                        padding: 0;
                        width: 35px;
                        height: 35px;
                        position: absolute;
                        background-color:#36A689;
                        border-radius: 50%;
                        line-height: 46px;
                        text-align: center;
                        // left: 29%;
                        top: -58px;
                        // color: #fff;
                        transform-origin: 18px 98px;
                        z-index: 10000000000;
                    }
                    .lvItem{
                        display: flex;
                        flex-direction: row;
                        // align-items: center;
                        justify-content: center;
                        margin: 0;
                        list-style-type: none;
                        padding: 0;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        background-color:#8bf3d8;
                        border-radius: 50%;
                        line-height: 15px;
                        text-align: center;
                        // left: 29%;
                        top: -19px;
                        color: #36a58b;
                        transform-origin: 8px 59px;
                        .word{
                            display: inline-block;
                            line-height: 15px;
                            text-align: center;
                            white-space: nowrap;
                            font-size: 12px;
                            -webkit-transform-origin-x: 0; //缩小后文字居左
                            -webkit-transform: scale(0.80);
                            
                        }
                    }
                }
            }
            .card{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                .score{
                    width: 100%;
                    height: 240px;
                    // background: #DADADA;
                    position: fixed;
                    // left: 3%;
                    bottom: 20px;
                    border-radius: 30px;
                    z-index: 10;
                    // display: flex;
                    flex-direction: row;
                    .title{
                        flex: 1;
                        border-right: 1px solid #fff;
                        letter-spacing: 5px;
                        font-size: 24px;
                        font-family: Noto Sans S Chinese;
                        font-weight: 400;
                        color: #333333;
                        margin: 20px 0 20px 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .scoreTitle{
                            flex: 1;
                            text-align: center;
                            letter-spacing: 5px;
                            font-size: 18px;
                            font-family: Noto Sans S Chinese;
                            font-weight: 400;
                            color: #333333;
                            .word{
                                margin: 10px 0 10px 0;
                            }
                        }
                        .Button{
                            flex: 2;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            width: 100%;
                            height: 100%;
                            .selAllButton{
                                height: 50%;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .button{
                                    width: 80%;
                                    height: 40px;
                                    font-size: 18px;
                                    border-radius: 20px;
                                    background: #2CBD74;
                                    color: #fff;
                                    text-align: center;
                                    line-height: 40px;
                                    cursor: pointer;
                                }
                                .button:hover {
                                    box-shadow: #909399 0px 10px 10px;
                                    transition: all  0.5s;
                                }
                            }
                            .counterButton{
                                height: 50%;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .button{
                                    width: 80%;
                                    height: 40px;
                                    font-size: 18px;
                                    border-radius: 20px;
                                    background: #fff;
                                    color: #333;
                                    text-align: center;
                                    line-height: 40px;
                                    cursor: pointer;
                                }
                                .button:hover {
                                    box-shadow: #909399 0px 10px 10px;
                                    transition: all  0.5s;
                                }
                            }
                        }
                    }
                    .scoreBtn{
                        flex: 6;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        .scoreBtnCardList{
                            flex: 0 0 8%;
                            height: 160px;
                            margin: 0 1% 0 1%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            .scoreBtnCard{
                                width: 90%;
                                height: 80%;
                                border-radius: 40px;
                                display: flex;
                                flex-flow: column;
                                background: #FAE9C2;
                                border-radius: 40px;
                                border: 6px solid #9D7635;
                                cursor: pointer;
                                .scoreBtnCardIcon{
                                    flex: 3;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    .elIconReduce{
                                        transform: scale(0.8);
                                        margin: 15px 5px 0 0;
                                        cursor: pointer;
                                    }
                                    .elIconPlus{
                                        transform: scale(0.8);
                                        margin: 15px 0 0 5px;
                                        cursor: pointer;
                                    }
                                }
                                .scoreBtnCardTitle{
                                    flex: 2;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: #333333;
                                    transform: scale(0.8);
                                }
                            }
                            .scoreBtnCard:hover{
                                -webkit-box-shadow: #606266 0px 10px 10px;
                                -moz-box-shadow: #606266 0px 10px 10px;
                                box-shadow: #fff 0px 0 10px;
                                transition: all  0.5s;
                            }
                        }
                    }
                    .operation{
                        display: flex;
                        width: 100%;
                        height: 80px;
                        align-items: center;
                        justify-content: center;
                        .buttonOne{
                            width: 200px;
                            height: 60px;
                            background: #FAE9C2;
                            border-radius: 23px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 4%;
                            cursor: pointer;
                            box-shadow: 0px 5px #9D7635;
                            .btnOne{
                                color: #9D7635;
                                font-weight: 700;                           
                                font-family: PingFangSC-Semibold, PingFang SC;                         
                                font-size: 20px;
                            }
                        }
                        .buttonTwo{
                            width: 200px;
                            height: 60px;
                            // background: #4BD9B4;
                            border-radius: 23px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 4%;
                            cursor: pointer;
                            // box-shadow: 0px 5px #36A689;
                            .btnTwo{
                                color: #fff;                    
                                font-family: PingFangSC-Semibold, PingFang SC;                         
                                font-size: 35px;
                                /deep/ .el-icon-plus{
                                    font-weight: 700 !important;        
                                }
                                /deep/.el-icon-minus{
                                    font-weight: 700 !important;  
                                }
                            }
                        }
                    }
                }
                .scoreOpacity{
                    width: 100%;
                    height: 250px;
                    background: #0E0E0E;
                    position: fixed;
                    bottom: 20px;
                    border-radius: 30px;
                    z-index: 0;
                    opacity: 0;
                    
                }
            }
            
        }
    }
    .opera{
         z-index: 1000;
        .btnDetails{
            position: absolute;
            right: 50px;
            bottom: 70px;
            width: 200px;
            height: 60px;
            background: #2CBD74;
            border-radius: 20px;
            text-align: right;
            cursor: pointer;
            font-size: 24px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #FFFCFD;
            line-height: 60px;
            text-align: center;
        }
        .btnEnd{
            position: absolute;
            right: 50px;
            bottom: 20px;
            width: 200px;
            height: 60px;
            background: #fff;
            border-radius: 20px;
            text-align: right;
            cursor: pointer;
            font-size: 24px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #0E0E0E;
            line-height: 60px;
            text-align: center;
        }
        .btnSelAll{
            position: absolute;
            left: 50px;
            bottom: 120px;
            width: 200px;
            height: 60px;
            background: #2CBD74;
            border-radius: 20px;
            text-align: right;
            cursor: pointer;
            font-size: 24px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #FFFCFD;
            line-height: 60px;
            text-align: center;
        }
        .btnCounter{
            position: absolute;
            left: 50px;
            bottom: 20px;
            width: 200px;
            height: 60px;
            background: #fff;
            border-radius: 20px;
            text-align: right;
            cursor: pointer;
            font-size: 24px;
            font-family: Noto Sans S Chinese;
            font-weight: 400;
            color: #0E0E0E;
            line-height: 60px;
            text-align: center;
        }
    }
}
    .checkSucCard{
        position: fixed;
        left: 50%;
        top: 48%;
        width: 760px;
        height: 475px;
        background: url("../../assets/image/bg-login-card.png") center/cover no-repeat;
        transform: translateY(-50%) translateX(-50%);
        .checkSuc{
            position: absolute;
            left: 65px;
            top: 40px;
            width: 630px;
            height: 410px;
            background: #FFFCFD;
            border-radius: 135px;
            .tip{
                position: absolute;
                left: 50%;
                top: -16%;
                width: 400px;
                height: 110px;
                background: url("../../assets/image/tip.png") center/cover no-repeat;
                transform: translateY(-50%) translateX(-50%);
            }
            .sucIcon{
                position: absolute;
                left: 50%;
                top: 27%;
                width: 170px;
                height: 183px;
                background: url("../../assets/image/checkSuc.gif") center/cover no-repeat;
                transform: translateY(-50%) translateX(-50%);
            }
            .sucTip{
                position: absolute;
                top: 52%;
                width: 100%;
                .sucTipMessage{
                    text-align: center;
                    font-size: 25px;
                    font-family: Noto Sans S Chinese;
                    font-weight: 400;
                    color: #939393;
                    .msgOne{
                        letter-spacing:8px;
                    }
                    .msgTwo{
                        letter-spacing:8px;
                        margin-top: 10px;
                    }
                    .msgThree{
                        word-spacing: 10px;
                        margin-top: 30px;
                        color: #36A689;
                    }
                    .beginClassTipOne{
                        letter-spacing:8px;
                    }
                    .beginClassTipTwo{
                        letter-spacing:8px;
                    }
                }
            }
            .sucClassTip{
                position: absolute;
                top: 30%;
                width: 100%;
                .sucTipMessage{
                    text-align: center;
                    font-size: 25px;
                    font-family: Noto Sans S Chinese;
                    font-weight: 400;
                    color: #939393;
                    .beginClassTipOne{
                        letter-spacing:8px;
                        margin-bottom: 30px;
                    }
                    .beginClassTipTwo{
                        letter-spacing:8px;
                    }
                }
            }
            .operation{
                position: absolute;
                left: 50%;
                top: 75%;
                width: 500px;
                height: 110px;
                transform: translateY(-50%) translateX(-50%);
                display: flex;
                flex-flow:  row nowrap;
                justify-content: space-between;
                align-items: center;
                letter-spacing:3px;
                .goBack{
                    width: 200px;
                    height: 60px;
                    border: 1px solid #2CBD74;
                    border-radius: 42px;               
                    color: #2CBD74;
                    line-height: 60px;
                    font-size: 24px;
                    cursor: pointer;
                    text-align: center;
                }
                .sure{
                    width: 200px;
                    height: 60px;
                    background: #2CBD74;
                    border-radius: 42px;
                    color: #FFFCFD;;
                    line-height: 60px;
                    font-size: 24px;
                    cursor: pointer;
                    text-align: center;
                }
            }
        }
    }
    /deep/.el-table--scrollable-y ::-webkit-scrollbar {
        display: none;
    }
    /deep/ .el-button--success{
        background-color: #2CBD74;
        border-color: #2CBD74;
        width: 80%
    }
    /deep/ .el-button :hover{
        background-color: #67C23A;
        border-color: #67C23A;
    }
    /deep/ .el-button{
        width: 80%
    }
    .studentDetails:nth-child(6){
        margin-right: 0% !important;
    }
    .studentDetails:nth-child(12){
        margin-right: 0% !important;
    }
    .studentDetails:nth-child(18){
        margin-right: 0% !important;
    }
    .flip-list-move {
        transition: all 3s ease;
    }
    .scoreItem{
        display: block;
        transform-origin:20% 40%;
    }
    /deep/ .el-loading-spinner{
        display: none;
    }
    /deep/ .el-loading-mask{
        z-index: 1;
    }
    .operation{
        display: flex;
    }
    .avaImg{
        width: 60px;
        height: 60px;
    }
    .leftArrowImg{
        width: 80px;
        height: 80px;
    }
    .rightArrowImg{
        width: 80px;
        height: 80px;
    }
    .stuImageUrl {
        width: 80px;
        height: 80px;
    }
    .aflpScoringItemIcon{
        width: 20px;
        height: 20px;
    }
    .itemLv{
        display: inline-block;
        width: 5px;
    }
    .cryImg{
        width: 60px;
        height: 60px;
        z-index: 100000000000;
        position: absolute;
        top: 30%;
        left: 50%;
    }
</style>
<style lang="scss" scoped>
    .tableDetails{
        height: calc(100vh - 200px)
    }
    .student{
        height: calc(100vh - 400px);
        overflow-y: scroll;
    }
    .student::-webkit-scrollbar {
        display: none;/*隐藏滚动条*/
    } 
    .attendClassDetails{
        height: calc(100vh - 110px)
    }
    img {
        image-rendering:-moz-crisp-edges;
        image-rendering:-o-crisp-edges;
        image-rendering:-webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode:nearest-neighbor;
    }
    .show-ani {
        animation: bigger 1.5s linear;
    }   
    @keyframes bigger {
        50% {
                transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg) translateZ(0) ;
            }
            60%,
            80% {
                transform:  scale3d(6, 6, 6) rotate3d(0, 0, 1, 10deg) translateZ(0);
                image-rendering:-moz-crisp-edges;
                image-rendering:-o-crisp-edges;
                image-rendering:-webkit-optimize-contrast;
                image-rendering: crisp-edges;
                -ms-interpolation-mode:nearest-neighbor;
            }

            70%,
            90%{
                transform:  scale3d(6, 6, 6) rotate3d(0, 0, 1, -10deg) translateZ(0);
                image-rendering:-moz-crisp-edges;
                image-rendering:-o-crisp-edges;
                image-rendering:-webkit-optimize-contrast;
                image-rendering: crisp-edges;
                -ms-interpolation-mode:nearest-neighbor;
            }

            100% {
                transform:  scale3d(1, 1, 1) translateZ(0);
            }
    }
</style>

